// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DeleteFile($id: String!) {
    successful: deleteFile(id: $id)
  }
`;

export default MUTATION;
